.awsscm-argo-landing-app-login-panel-split-line {
  /*  new lines  */
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

.awsscm-argo-landing-app-login-panel-split-line:before {
  content:" ";
  height: 1px;
  width: 120px;
  background: #D1D5DB;
  display: block;
  position: absolute;
  top: 180px;
  left: 22px;
}

.awsscm-argo-landing-app-login-panel-split-line:after{
  content:" ";
  height: 1px;
  width: 120px;
  background: #D1D5DB;
  display: block;
  position: absolute;
  top: 180px;
  right: 22px;
}

.awsscm-landing-app-cta_wrapper {
  display: flex;
  align-items: center;
  gap: 24px;
}

.awsscm-argo-landing-app-vendor-signin {
  font-size: medium;
  font-weight: bold;
}


